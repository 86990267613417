import React, {useState} from 'react';
import wordlist from './wordlist.json';
import './App.css';
import Select from 'react-select';

const RandomArrayItem = (list) => list[Math.floor(Math.random() * list.length)];

const Word = (props) =>(<p className="word">{props.word}</p>);

const WordlistSelector = (props) => {
    let options = props.listnames.map(ln => {return {value: ln, label: ln}});
    let selectedlistname = options.filter(item => item.value===props.selectedlistname);

    return (
        <Select options={options} value={selectedlistname} onChange={e=>{props.onListSelected(e.value)}} />
    );
}

const Introduction = (props) => {
  return (
    <div className="intro">
      <ol className="onboarding">
        <li>Start a call on your favourite platform</li>
        <li>Create a shared whiteboard <p className="onboarding-subscript"><a href="https://awwapp.com/" target="_blank">AWW</a> works well, especially on mobile.</p></li>
        <li>Choose your difficulty: <WordlistSelector listnames={Object.keys(props.wordlist)} selectedlistname={props.selectedWordlist} onListSelected={props.onWordListSelected}/></li>
      </ol>
      <button className="btn-primary" onClick={props.onLetsGoClick}>Let's Go!</button>
    </div>
    
  );
}


const MainGame = (props) => {
  
  const wordlistToUse = props.wordLists[props.selectedList];
  const [currentWord, setCurrentWord] = useState(RandomArrayItem(wordlistToUse));

  return (
    <div className="maingame">
        <div className="centered wordcontainer">
          <Word word={currentWord} />
        </div>
        <div className="menucontainer">
          <WordlistSelector onListSelected={props.onWordListSelected} listnames={Object.keys(props.wordLists)} selectedlistname={props.selectedList}/>
          <button className="btn-primary" onClick={() => setCurrentWord(RandomArrayItem(wordlistToUse))}>New Word</button>
        </div>
    </div>
  );
}

class App extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      wordlist: wordlist,
      selectedWordlist: "Hard"
    }

    this.handleLetsGoClick = this.handleLetsGoClick.bind(this);
    this.handleWordListSelected = this.handleWordListSelected.bind(this);
  }

  handleLetsGoClick() {
    this.setState({ready: true});
  }

  handleWordListSelected(selectedList) {
    this.setState({selectedWordlist: selectedList});
  }

  render() {
    return (
      <>
        {this.state.ready ? <MainGame selectedList={this.state.selectedWordlist} wordLists={this.state.wordlist} onWordListSelected={this.handleWordListSelected} /> : <Introduction onLetsGoClick={this.handleLetsGoClick} selectedWordlist={this.state.selectedWordlist} wordlist={this.state.wordlist} onWordListSelected={this.handleWordListSelected} />}
        <footer>
          <p>Made by <a href="https://richardbrunt.co.uk/" className="logo-text" target="_blank">Richard Brunt</a></p>
        </footer>
      </>
    );
  }
}

export default App;
